@font-face {
  font-family: Roboto-Regular;
  src: url("assets/fonts/Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: Roboto-Bold;
  src: url("assets/fonts/Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: Roboto-Light;
  src: url("assets/fonts/Roboto/Roboto-Light.ttf");
}

body {
  margin: 0;
  background-color: #3C3C3B;
  color: #F4F4F4;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #FFFFFF;
  text-decoration: none;
  font-size:medium;
  text-align: center;
  padding: 10px;
}

@import url(./style/toast.css);